import React, { useState, useEffect } from "react";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import ExtendedProductGallery from "@src/stories/components/ProductGallery/ExtendedProductGallery";

const SizeGuideModal = ({ product, onClose }) => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-80 bg-primary/50">
      <div className="relative w-full max-w-xl p-3 mx-3 bg-white rounded-lg shadow-xl lg:max-w-2xl lg:p-8 lg:mx-0">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-base font-bold min-h-[auto] h-auto w-auto p-0 lg:text-xl focus:outline-none hover:text-highlight"
        >
          <i className="icon-close"></i>
        </button>
        <div className="overflow-y-auto max-h-[80vh]"
          dangerouslySetInnerHTML={{ __html: product.size_guide }}
        >
        </div>
      </div>
    </div>
  );
};

export const QuickViewModal = ({ settings, product, onClose }) => {
  const isMobile = useMobileBreakpoint("1024px");
  const AjaxCartProductForm = "ajax-cart-product-form" as keyof JSX.IntrinsicElements;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const activeSwatchUrl = product.active_swatch_url;
  const activeSwatch = product.product_swatches.find(swatch => swatch.url === activeSwatchUrl);
  const [hoveredLabel, setHoveredLabel] = useState(activeSwatch?.label?.split("|")[0]);

  const heritageSwatches = product.product_swatches.filter(swatch => swatch.label?.includes("Heritage"));
  const limitedEditionSwatches = product.product_swatches.filter(swatch => swatch.label?.includes("Limited Edition"));
  const uncategorizedSwatches = product.product_swatches.filter(swatch => !swatch.label?.includes("Heritage") && !swatch.label?.includes("Limited Edition"));

  const variantSize = product.variants.map(variant => variant.option3);
  const [isSizeGuideVisible, setIsSizeGuideVisible] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedSizeVariantId, setSelectedSizeVariantId] = useState("");
  const [hoveredSize, setHoveredSize] = useState("");

  const currentIndex = hoveredIndex !== null ? hoveredIndex : activeIndex;

  const toggleTooltip = () => {
    setIsTooltipVisible(!isTooltipVisible);
  };

  const handleTOGClick = (index) => {
    setActiveIndex(index);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const toggleSizeGuideModal = () => {
    setIsSizeGuideVisible(!isSizeGuideVisible);
  };

  const renderSwatches = (swatches) => {
    return (
      <div className="flex flex-wrap items-center gap-2">
        {swatches.map((swatch, index) => {
          const isActive = swatch.url === activeSwatchUrl;
          const swatchLabel = swatch.label?.split("|")[0];
          return (
            <a
              key={index}
              href={isActive ? undefined : swatch.url}
              className="flex items-center"
              onMouseEnter={() => setHoveredLabel(swatchLabel)}
              onMouseLeave={() => setHoveredLabel(activeSwatch?.label?.split("|")[0])}
            >
              {swatch.image ? (
                <ExtendedImage
                  imageUrl={swatch.image}
                  alt={`Swatch color for ${swatch.color}`}
                  isLazy={true}
                  classes={`w-8 h-8 rounded-full lg:w-12 lg:h-12 ${isActive ? 'border-2 border-primary' : ''}`}
                />
              ) : (
                <span
                  className={`flex items-center justify-center w-8 h-8 rounded-full lg:w-12 lg:h-12 ${
                    isActive ? 'border-2 border-primary' : 'border-transparent'
                  }`}
                  style={{ backgroundColor: swatch.color }}
                  title={swatch.color}
                />
              )}
            </a>
          );
        })}
      </div>
    );
  };

  const handleSizeChange = (size, variantId) => {
    setSelectedSize(size);
    setSelectedSizeVariantId(variantId);
    setHoveredSize("");
  };

  const handleSizeMouseEnter = (size) => {
    setHoveredSize(size);
  };

  const handleSizeMouseLeave = () => {
    setHoveredSize("");
  };

  const handleImageClick = () => {
    console.log('clicked')
  }

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  useEffect(() => {
    if (product.variants && product.variants.length > 0) {
      const availableVariant = product.variants.find(variant => variant.available);

      if (availableVariant) {
        setSelectedSize(availableVariant.option3);
        setSelectedSizeVariantId(availableVariant.id);
      } else {
        setSelectedSize(product.variants[0].option3 || "");
        setSelectedSizeVariantId(product.variants[0].id);
      }
    }
  }, [product.variants]);
  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

  const selectedVariant = product.variants.find(variant => variant.id === selectedSizeVariantId);
  console.log('selectedVariant', selectedVariant)

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-opacity-80 bg-primary/50 lg:block">
      <div className="flex items-center max-w-3xl mx-3 lg:min-h-[calc(100%-3.5rem)] lg:my-7 lg:mx-auto xl:max-w-[1084px]">
        <div className="relative flex items-center px-8 bg-white rounded-lg py-14">
          <button
            onClick={onClose}
            className="absolute text-gray-500 min-h-[auto] h-auto w-auto top-5 px-0 right-5 hover:text-highlight"
          >
            <i className="icon-close-big"></i>
          </button>
          <div className="flex flex-col w-full lg:flex-row lg:gap-9 lg:items-center">
            {!isMobile && (
              <div className={"product-gallery lg:w-1/2"}>
                <ExtendedProductGallery
                  images={product.images}
                  galleryType={"thumbnail"}
                  videoUrl={null}
                  product={product}
                  badges={settings.promotional_badges}
                  onImageClick={() => handleImageClick()}
                />
              </div>
            )}

            <div className="flex flex-col lg:w-1/2">
              <div className="flex gap-3">
                <img
                  src={product.images[0].url}
                  alt={product.title}
                  className="object-cover w-[84px] h-[100px] lg:hidden"
                />
              <div>
                <h3 className="!text-[18px]/7 text-primary mb-3 font-body lg:!text-[24px]/7">
                  {product.title}
                </h3>
                <div className="flex items-start gap-3">
                  <span className="text-[24px]/7 text-primary font-semibold font-body">
                    {currencySymbol}{ (product.price / 100).toFixed(2) }
                  </span>
                  {/* <span className="font-semibold line-through text-base/7 text-tertiary font-body">
                    ${ (product?.compare_at_price / 100).toFixed(2) }
                  </span> */}
                  {product.variants.map((variant, index) => (
                    variant.compare_at_price !== null && variant.compare_at_price > 0 && (
                      <span key={index} className="font-semibold line-through text-base/7 text-tertiary font-body">
                        {currencySymbol}{ (variant.compare_at_price / 100).toFixed(2) }
                      </span>
                    )
                  ))}
                </div>
              </div>
              </div>

              {/* Product TOG */}
              <div className="mt-2 py-2.5">
                <div className="flex items-center justify-between mb-1.5 relative">
                  <div className="text-base/[22px] font-semibold font-body lg:text-xl">
                    <span className="text-primary">TOG</span>

                    <span className="ml-3 text-tertiary lg:ml-4">
                      {hoveredIndex !== null
                        ? product.tog_siblings[hoveredIndex]?.tog_rating.tog_feel
                        : product.tog_siblings[activeIndex]?.tog_rating.tog_feel}
                    </span>
                  </div>

                  <div className="relative">
                    <span
                      onClick={toggleTooltip}
                      className="cursor-pointer text-[#A87C64] underline font-body font-semibold text-xs/[21px] lg:text-sm/7"
                    >
                      {product.tog_tooltip_title}
                    </span>

                    {isTooltipVisible && (
                      <div className="absolute bg-white text-[#323D3E] p-4 rounded-lg shadow-lg top-full right-0 mt-2 w-80 z-10 border border-[#FAF8F6]">
                        <div className="flex items-center justify-between mb-2">
                          <span className="text-sm font-semibold font-body text-highlight">
                            {product.tog_tooltip_title}
                          </span>
                          <button
                            className="p-0 h-auto min-h-[auto] w-auto text-primary/50 hover:text-primary focus:outline-none"
                            onClick={toggleTooltip}
                          >
                            <i className="icon-close text-[10px]"></i>
                          </button>
                        </div>
                        <div className="text-xs text-primary font-body">
                          {product.tog_tooltip_description}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-wrap items-center space-x-2 lg:space-x-4">
                  {product.tog_siblings.map((sibling, index) => (
                    sibling.tog_rating.tog_title === product.tog_metafields?.tog_title ? (
                      <span
                        key={index}
                        className="px-3 py-2 font-semibold rounded-xl font-body text-xs/5 lg:text-base/7 bg-[#9CAFBD] text-[#FDFCFC] lg:px-4 lg:py-3"
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {sibling.tog_rating.tog_title}
                      </span>
                    ) : (
                      <a
                        key={index}
                        href={sibling.url}
                        onClick={() => handleTOGClick(index)}
                        className="px-3 py-2 font-semibold rounded-xl font-body text-xs/5 lg:text-base/7 bg-darker text-primary hover:text-highlight lg:px-4 lg:py-3"
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {sibling.tog_rating.tog_title}
                      </a>
                    )
                  ))}
                </div>

                <p className="mt-1 text-xs/[18px] font-normal font-body text-primary lg:mt-2 lg:text-base">
                {product.tog_siblings[currentIndex]?.tog_rating.tog_temp_range_c && (
                  <>
                    Perfect for {product.tog_siblings[currentIndex].tog_rating.tog_temp_range_c}°C
                    {product.tog_siblings[currentIndex].tog_rating.tog_temp_range_f && (
                      <> / {product.tog_siblings[currentIndex].tog_rating.tog_temp_range_f}°F</>
                    )}
                  </>
                )}
                </p>
              </div>

              {/* Product Swatches */}
              <div className="border-y border-primary/10 py-2.5">
                <div className="text-base/[22px] font-semibold font-body mb-2 lg:text-xl lg:mb-3">
                  <span className="text-primary">Print</span>
                  <span className="ml-3 text-tertiary lg:ml-4">{hoveredLabel}</span>
                </div>

                {uncategorizedSwatches.length > 0 && (
                  <div className="mb-3">
                    {renderSwatches(uncategorizedSwatches)}
                  </div>
                )}

                {heritageSwatches.length > 0 && (
                  <div className="mb-3">
                    <div className="text-sm font-semibold font-body lg:text-base">Heritage</div>
                    {renderSwatches(heritageSwatches)}
                  </div>
                )}

                {limitedEditionSwatches.length > 0 && (
                  <div className="mb-3">
                    <div className="text-sm font-semibold font-body lg:text-base">Limited Edition</div>
                    {renderSwatches(limitedEditionSwatches)}
                  </div>
                )}
              </div>

              {/* Product Size Options */}
              <div className="flex items-center justify-between relative py-2.5">
                <div className="text-base/[22px] font-semibold font-body lg:text-xl lg:mb-3">
                  <span className="text-primary">
                    Size
                  </span>
                  <span className="ml-3 text-tertiary lg:ml-4">
                    {hoveredSize || selectedSize}
                  </span>
                </div>
                <div className="relative">
                  <span
                    className="cursor-pointer text-[#A87C64] underline font-body font-semibold text-xs/[21px] lg:text-sm/7"
                    onClick={toggleSizeGuideModal}
                  >
                    Size Guide
                  </span>
                </div>
              </div>
              <div className="flex flex-wrap items-center pb-4 gap-y-3 gap-x-3 pdp-options lg:gap-y-6">
                {product.variants.map((variant, index) => (
                  <div key={index}>
                    <input
                      type="radio"
                      id={`option-size-${index}`}
                      name="option-size"
                      value={variant.option3}
                      checked={selectedSize === variant.option3}
                      onChange={() => handleSizeChange(variant.option3, variant.id)}
                      onMouseEnter={() => handleSizeMouseEnter(variant.option3)}
                      onMouseLeave={handleSizeMouseLeave}
                      className={`hidden cursor-pointer ${
                        variant.available
                          ? "[&:checked+label]:bg-[#A87C64] [&:checked+label]:text-[#FDFCFC] [&:not(:checked)+label]:bg-darker [&:not(:checked)+label]:text-primary"
                          : "disabled cursor-not-allowed"
                      }`}
                    />
                    <label
                      htmlFor={`option-size-${index}`}
                      onMouseEnter={() => handleSizeMouseEnter(variant.option3)}
                      onMouseLeave={handleSizeMouseLeave}
                      className={`px-3 py-2 rounded-lg text-xs/5 font-body lg:text-base lg:py-3 lg:px-4 lg:rounded-xl ${
                        variant.available
                          ? "cursor-pointer bg-darker text-primary"
                          : "cursor-not-allowed"
                      }`}
                    >
                      {variant.option3}
                    </label>
                  </div>
                ))}
              </div>

              <AjaxCartProductForm className="quick-add__form">
                <form
                  action="/cart/add"
                  id={`product_form_${product.id}`}
                  className="product-form"
                  noValidate
                  data-prodify-product-form
                >
                  <input
                    type="hidden"
                    name="id"
                    value={selectedSizeVariantId}
                  />
                  <button
                    className={`btn-primary w-full mt-2 ${!selectedVariant?.available ? 'cursor-not-allowed' : ''}`}
                    disabled={!selectedVariant?.available}
                  >
                    {selectedVariant?.available ? 'Add to bag' : 'Out of stock'}
                  </button>

                  <div data-ajax-cart-errors="form" className="mt-2 text-xs text-[#F8624E] font-body"></div>
                </form>
              </AjaxCartProductForm>
            </div>
          </div>
        </div>
      </div>

      {/* Size Guide Modal */}
      {isSizeGuideVisible &&
        <SizeGuideModal
          product={product}
          onClose={toggleSizeGuideModal}
        />
      }
    </div>
  );
};

export default QuickViewModal;
