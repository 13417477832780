import React, { ReactNode, useState } from "react";
import { clsx } from 'clsx';
import './Collapsible.scss';

type CollapsibleProps = {
    title: string,
    openIcon?: string,
    closeIcon?: string,
    titleClass?: string,
    contentClass?: string,
    children: ReactNode,
    active?: boolean,
    disabled?: boolean,
    desktopDisabled?: boolean,
    nested?: boolean,
    tier?: 1 | 2 | undefined,
    iconColor?: string,
    borderColor?:string,
};

export function Collapsible(props: CollapsibleProps) {
  const {
    title,
    active = false,
    openIcon = 'icon-at-plus',
    closeIcon = 'icon-at-minus',
    titleClass = 'b2',
    contentClass = 'b2',
    children,
    disabled = false,
    desktopDisabled = false,
    nested = false,
    tier = null,
    iconColor,
    borderColor = '#FDFCFC',
  } = props;

  const [isActive, setIsActive] = useState(active);

  function toggle() {
    if (disabled) return;

    setIsActive(!isActive);
  }

  return (
      <div
          className={clsx({
              'collapsible': true,
              'collapsible--active-nested-1': tier == 1 && isActive,
              'collapsible--disabled-nested-1': tier == 1 && disabled,
              'collapsible--active-nested-2': tier == 2 && isActive,
              'collapsible--disabled-nested-2': tier == 2 && disabled,
              'collapsible--active': !tier && isActive,
              'collapsible--disabled': !tier && disabled,
              'collapsible--desktop-disabled': desktopDisabled
          })}
      >
          <div
              className={`collapsible__title ${titleClass}`}
              onClick={() => toggle()}
          >
              {title}

              {!disabled && !tier &&
                  <>
                    <span
                        className={'collapsible__expand-icon ' + openIcon}
                        style={iconColor ? { color: iconColor } : {}}
                    ></span>
                    <span
                        className={'collapsible__collapse-icon ' + closeIcon}
                        style={iconColor ? { color: iconColor } : {}}>
                    </span>
                  </>
              }
              {!disabled && tier == 1 &&
                  <>
                    <span
                        className={`collapsible__expand-icon-nested-1 ${openIcon}`}
                        style={iconColor ? { color: iconColor } : {}}
                    ></span>
                    <span
                        className={`collapsible__collapse-icon-nested-1 ${closeIcon}`}
                        style={iconColor ? { color: iconColor } : {}}
                    ></span>
                  </>
              }
              {!disabled && tier == 2 &&
                  <>
                    <span
                        className={`collapsible__expand-icon-nested-2 ${openIcon}`}
                        style={iconColor ? { color: iconColor } : {}}
                    ></span>
                    <span
                        className={`collapsible__collapse-icon-nested-2 ${closeIcon}`}
                        style={iconColor ? { color: iconColor } : {}}
                    ></span>
                  </>
              }
          </div>

          <div className={clsx({
              [`collapsible__content ${contentClass}`]: !tier,
              'collapsible__content-nested-1': tier == 1,
              'collapsible__content-nested-2': tier == 2,
          })}
          style={{ borderColor }}
          >
              {children}
          </div>
      </div>
  )
};