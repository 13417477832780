import React, { useState } from "react";
import { Accordion } from "@project-stories/elements/Accordion/Accordion";
import { Button } from "@arctheme-components/elements/Button/Button";
import { Headings } from "@arctheme-components/elements/Headings/Headings";
import { FAQTabs } from "../FAQTabs/FAQTabs";
import { TextField } from "@arctheme-components/elements/TextField/TextField";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { Collapsible } from "@project-stories/elements/Collapsible/Collapsible";
import { Media } from "@project-stories/elements/Media/Media";
import Image from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";

const StackedLayout = ({ blocks, settings, isMobile }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("All");
  const [visibleCount, setVisibleCount] = useState(settings.load_more_limit);

  const faqItems = blocks
    .filter((block) => block.type === "column_block")
    .map((faqItem) => ({
      title: faqItem.settings.title,
      content: faqItem.settings.content,
      image: faqItem.settings.image,
      image_position: faqItem.settings.image_position,
      video: faqItem.settings.video,
      category: faqItem.settings.category,
      filter_tag: faqItem.settings.filter_tag,
      column: faqItem.settings.column,
    }));

  const imageBlock = blocks.find((block) => block.type === "column_image");
  const ctaBlock = blocks.find((block) => block.type === "column_CTA");

  const filteredFaqItems = faqItems.filter((item) => {
    const matchesSearch = item.title
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesCategory =
      activeTab === "All" || item.filter_tag === activeTab;
    return matchesSearch && matchesCategory;
  });

  const uniqueFilterTags = [
    ...new Set(faqItems.map((item) => item.filter_tag)),
  ];

  const tabs = [
    { label: "All FAQ's", value: "All" },
    ...uniqueFilterTags.map((tag) => ({
      label: tag,
      value: tag,
    })),
  ];

  return (
    <div className={`faq`}>
      <div
        className={`flex flex-col items-${settings.content_alignment_mobile} lg:items-${settings.content_alignment_desktop} w-[${settings.width_mobile}px] lg:w-[${settings.width}px] justify-${settings.content_position_desktop} mx-auto`}
      >
        <div
          className={`faq-top-section flex flex-col space-y-3 lg:space-y-4`}
        >
          {(isMobile ? settings.title_mobile : settings.title) && (
            <Paragraph
              text={isMobile ? settings.title_mobile : settings.title}
              type="font-display font-bold text-2xl/10 lg:text-4xl"
            />
          )}

          {settings.subtext && (
            <div className="faq__container-subcopy" style={{ textAlign: "left" }}>
              <Paragraph
                text={settings.subtext}
                type="font-body font-normal text-sm lg:text-base"
              />
            </div>
          )}

          {settings.button_label && settings.button_url && (
            <a href={settings.button_url} className="text-base underline font-body">
              {settings.button_label}
            </a>
          )}

          {ctaBlock && (
            <Button
              label={ctaBlock.settings.cta_text}
              style={`${ctaBlock.settings.cta_style}`}
              link={`${ctaBlock.settings.cta_url}`}
            />
          )}
        </div>
        {(settings.show_filter_bar || settings.show_search_bar) && (
          <div className="w-full faq__container-filters">
            {settings.show_filter_bar && (
              <FAQTabs
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={setActiveTab}
                settings={settings}
              />
            )}

            {settings.show_search_bar && (
              <div className={`faq__container-tabs`}>
                <TextField
                  placeholder="Search our FAQ's"
                  icon="search"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        )}
        <div className={"faq__container-accordion w-full mt-4 lg:mt-0"}>
          {filteredFaqItems.map((item, index) => (
            <Collapsible
              key={index}
              title={item.title}
              titleClass={`font-body font-semibold text-base/7  lg:text-xl`}
              contentClass="b2 border-b"
              openIcon="icon-chevron-down-white text-[5px]"
              closeIcon="icon-chevron-down-white rotate-180 text-[5px]"
              iconColor={settings.icon_and_border_color}
              borderColor={settings.icon_and_border_color || '#FDFCFC'}
            >
              {(item.image_position === "top" && (item.image || item.video)) && (
                <div className="accordion__content-column">
                  <Media
                    videoItem={item.video}
                    imageItem={item.image}
                    index={index}
                  />
                </div>
              )}
              {item.content && (
                <div
                  className={`accordion__content-column ${
                    item.image_position === "bottom" ? "" : "pb-4"
                  }`}
                >
                  <Paragraph
                    text={item.content}
                    type={`${
                      isMobile ? "text-sm font-body" : "text-base font-body"
                    }`}
                  />
                </div>
              )}
              {(item.image_position === "bottom" && (item.image || item.video)) && (
                <div className="pb-4 accordion__content-column">
                  <Media
                    videoItem={item.video}
                    imageItem={item.image}
                    index={index}
                  />
                </div>
              )}
            </Collapsible>
          ))}
        </div>
        {settings.use_load_more && visibleCount < filteredFaqItems.length && (
          <div className={"faq__container-button-container"}>
            <div className={"faq__container-button"}>
              <Button
                label="Load More"
                style="secondary"
                onClick={() => setVisibleCount(visibleCount + 10)}
              />
            </div>
          </div>
        )}

        {imageBlock && (
          <div className={`faq-data basis-1/2`}>
            <Image imageUrl={imageBlock.image_url} alt={"Image"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StackedLayout;
