import * as React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import Flickity from 'flickity';
import useSearchSpring from "@src/hooks/useSearchSpring";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedProductTile from "@stories/components/ProductTile/ProductTile";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import "flickity/css/flickity.css";
import "./FeaturedCollections.scss";

const FeaturedCollections = ({ settings, blocks, product_id }) => {
  if (!blocks || blocks.length === 0) {
    return null;
  }

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const carouselRefs = useRef([]);
  const flktyRefs = useRef([]);

  const {
    fetchSimilarProducts,
    similarProducts,
    fetchCrossSellProducts,
    crossSellProducts
  } = useSearchSpring(null);

  const handleTabClick = (index) => {
    setLoading(true);
    setTimeout(() => {
      setActiveTab(index);
      setAnimationKey((prevKey) => prevKey + 1);
      setLoading(false);
    }, 300);
  };

  const initializeFlickity = useCallback(() => {
    if (settings.style !== 'grid' && carouselRefs.current[activeTab]) {
      if (flktyRefs.current[activeTab]) {
        flktyRefs.current[activeTab].destroy();
      }
      flktyRefs.current[activeTab] = new Flickity(carouselRefs.current[activeTab], {
        wrapAround: true,
        pageDots: isMobile,
        prevNextButtons: !isMobile,
        contain: false,
        cellAlign: 'left',
        adaptiveHeight: true,
        freeScroll: true,
      });
    }
  }, [activeTab, isMobile, settings.style]);

  useEffect(() => {
    initializeFlickity();
  }, [initializeFlickity, similarProducts, crossSellProducts]);

  useEffect(() => {
    const similarBlock = blocks.filter((block) => block.type === 'similar_block');
    const crossSellBlock = blocks.filter((block) => block.type === 'cross_sell_block');

    if (similarBlock.length > 0 && product_id) {
      fetchSimilarProducts(product_id);
    }

    if (crossSellBlock.length > 0 && product_id) {
      fetchCrossSellProducts(product_id);
    }
  }, [product_id, blocks]);

  const renderGrid = (productList, key) => (
    <div
      key={animationKey}
      className="grid grid-cols-2 w-full max-w-full gap-3 slide-fade-in-element lg:grid-cols-4 lg:gap-x-6 lg:px-[60px]"
    >
      {productList.slice(0, 4).map((product, index) => (
        <div
          key={index}
          className="flex items-center"
        >
          <ExtendedProductTile
            product={product}
            badges={product.promotional_badges}
            tog={product.tog_metafields}
            product_swatches={product.product_swatches}
          />
        </div>
      ))}
    </div>
  );

  const renderCarousel = (source, productList, key, singleBlock = false) => (
    <div
      ref={(el) => (carouselRefs.current[key] = el)}
      key={animationKey}
      className={`flex-col w-full max-w-full carousel slide-fade-in-element ${singleBlock ? 'lg:flex lg:justify-end' : ''}`}
    >
      {productList.slice(0, 10).map((product, index) => (
        <div
          key={index}
          className={`flex flex-col items-center mr-3 w-7/12 ${singleBlock || settings.layout === 'layout3' ? 'lg:w-[22%]' : 'md:w-5/12 xl:w-[28%]'} carousel-cell lg:mr-6`}
        >
          <ExtendedProductTile
            product={product}
            badges={product.promotional_badges}
            tog={product.tog_metafields}
            product_swatches={product.product_swatches}
            source={source}
          />
        </div>
      ))}
    </div>
  );

  const renderSingleBlock = (block) => {
    const renderProducts = () => {
      if (block.type === 'similar_block') {
        return renderCarousel('searchspring', similarProducts, animationKey, true);
      } else if (block.type === 'cross_sell_block') {
        return renderCarousel('searchspring', crossSellProducts, animationKey, true);
      } else if (block.type === 'product_block' && block.product_list && block.product_list.products && block.product_list.products.length > 0) {
        return renderCarousel('shopify', block.product_list.products, animationKey, true);
      } else if (block.collection && block.collection.products && block.collection.products.length > 0) {
        return settings.style !== 'grid'
          ? renderCarousel('shopify', block.collection.products, animationKey, true)
          : renderGrid(block.collection.products, animationKey);
      } else {
        return null;
      }
    };
  
    return (
      <>
        <div className="grid grid-cols-1 items-center mb-6 lg:px-[60px] lg:mb-4 lg:grid-cols-[1fr_auto]">
          {block.settings.title && (
            <ExtendedHeadings
              text={block.settings.title}
              type={"h2"}
              classes={`text-primary !text-2xl/[28px] mb-3 ${blocks.length === 1 ? 'text-left lg:col-span-2 lg:mb-0' : 'text-center lg:mb-6'} lg:!text-[32px]/9`}
            />
          )}
          {block.settings.description && (
            <Paragraph
              text={block.settings.description}
              type={`text-sm font-body ${blocks.length === 1 ? 'm-0 my-3 text-left' : 'text-center mb-4'} ${settings.style === 'grid' ? 'mb-3' : ''} ${block.settings.button_style === 'link' ? 'mt-3' : ''} lg:text-base lg:text-left`}
            />
          )}
          {block.settings.button_link && (
            <div className={`${blocks.length === 1 && settings.style !== 'grid' ? 'hidden justify-end' : ''} flex items-center ${blocks.length === 1 ? 'lg:flex' : 'justify-center lg:justify-start'}`}>
              <a
                href={block.settings.button_link}
                className={`${block.settings.button_style !== 'link' && block.settings.button_style !== 'link_button' ? 'px-6 py-3 text-base font-body uppercase hover:border-transparent hover:no-underline lg:w-max lg:min-w-[164px] lg:text-center lg:py-[17px]' : ''}
                  ${block.settings.button_style} ${block.settings.button_style === 'link_button' ? 'link normal-case font-display hover:!underline font-bold lg:text-xl/9': ''}`
                }
              >
                {block.settings.button_label}
                {block.settings.button_style !== 'link' && block.settings.button_style !== 'link_button' && (
                  <i className="icon-arrow-long-right text-[12px] pl-3"></i>
                )}
              </a>
            </div>
          )}
        </div>
        <div className={`flex justify-center lg:justify-end ${settings.style !== 'grid' ? '-mr-3 lg:mr-0 lg:pl-[60px]' : ''} `}>
          {renderProducts()}
        </div>
        {block.settings.button_style !== 'link' && (
          <a
            href={block.settings.button_link}
            className={`inline-block px-6 py-3 mt-6 text-base font-body cursor-pointer text-center uppercase
              ${block.settings.button_style} ${block.settings.button_style === 'link_button' ? 'button btn-outline': ''} hover:border-transparent hover:no-underline lg:hidden
            `}
          >
            {block.settings.button_label}
            <i className={`icon-arrow-long-right text-[12px] ${block.settings.button_style === 'link_button' ? 'pl-0': 'pl-3'}`}></i>
          </a>
        )}
      </>
    );
  };

  const renderBlock = (block, activeTab) => {
    return (
      <>
        {settings.layout === "layout3" && (
          <div className="grid grid-cols-1 items-center mb-6 lg:px-[60px] lg:grid-cols-[1fr_auto]">
            {block.settings.description && (
              <Paragraph
                text={block.settings.description}
                type={`text-sm font-body text-left ${block.settings.button_style === 'link' ? 'mt-3' : ''} lg:text-base`}
              />
            )}
            {block.settings.button_style === 'link_button' ? (
              <a
                href={block.settings.button_link}
                className={`hidden text-base font-body ${block.settings.button_style} link normal-case hover:underline font-bold lg:text-xl/9 lg:font-display lg:w-max lg:underline lg:block`}
              >
                {block.settings.button_label}
              </a>
            ) : (
              <a
                href={block.settings.button_link}
                className={`inline-block px-6 py-3 mt-6 text-base font-body cursor-pointer text-center uppercase ${block.settings.button_style} hover:border-transparent hover:no-underline lg:hidden`}
              >
                {block.settings.button_label}
                <i className="icon-arrow-long-right text-[12px] pl-3"></i>
              </a>
            )}
          </div>
        )}
        <div className={`flex justify-center lg:justify-end ${settings.style !== 'grid' ? '-mr-3 lg:mr-0 lg:pl-[60px]' : ''} `}>
          {loading ? (
            <div className="flex items-center justify-center w-full h-full">
              <div className="w-6 h-6 border-t-4 border-b-4 rounded-full border-primary animate-spin"></div>
            </div>
          ) : (
            block.type === 'similar_block' ? (
              renderCarousel('searchspring', similarProducts, activeTab)
            ) : block.type === 'cross_sell_block' && crossSellProducts && crossSellProducts.length > 0 ? (
              renderCarousel('searchspring', crossSellProducts, activeTab)
            ) : block.type === 'product_block' && block.product_list && block.product_list.products && block.product_list.products.length > 0 ? (
              renderCarousel('shopify', block.product_list.products, activeTab)
            ) : (
              block.collection && block.collection.products && block.collection.products.length > 0 ? (
                renderCarousel('shopify', block.collection.products, activeTab)
               ) : null
            )
          )}
        </div>
        <a
          href={blocks[activeTab].settings.button_link}
          className={`inline-block w-full text-center px-6 py-3 mt-4 text-base font-body uppercase ${
            blocks[activeTab].settings.button_style === 'link_button' ? 'button btn-outline' : ''
          } ${blocks[activeTab].settings.button_style} hover:no-underline lg:hidden`}
        >
          {blocks[activeTab].settings.button_label}
          <i className={`icon-arrow-long-right text-[12px] ${block.settings.button_style === 'link_button' ? 'pl-0': 'pl-3'}`}></i>
        </a>
      </>
    );
  };

  return (
    <div className={`flex flex-col px-3 featured-collections ${blocks.length === 1 ? '' : 'sm:items-center sm:justify-center'} lg:px-0`}>
      {settings.title && (
        <ExtendedHeadings
          text={settings.title}
          type={"h2"}
          classes={`text-primary mb-5 !text-2xl/7 lg:!text-4xl lg:mb-6 ${blocks.length === 1 ? '' : 'text-center'}`}
        />
      )}

      {blocks.length === 1 ? (
        renderSingleBlock(blocks[0])
      ) : (
        <>
          <div className="relative pb-[2px] -mr-3 overflow-x-auto hide-scrollbar md:overflow-x-visible lg:mr-0">
            <ul className="relative flex mb-3 gap-x-3 lg:gap-x-10 justify-between lg:mb-6 lg:pb-0 min-w-max after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[2px] after:bg-primary/20 lg:justify-center">
              {blocks.map((block, index) => {
                const hasProductsInProductList = !(block.type === 'product_block' && (!block.product_list || block.product_list.products.length === 0));
                return block.settings.title && hasProductsInProductList && (
                  <li
                    key={index}
                    className={`relative cursor-pointer flex justify-center items-center py-3 px-4 text-primary font-body text-center transition duration-300 font-semibold text-base/9 lg:text-xl/10 lg:py-2 ${
                      activeTab === index ? "active" : ""
                    }`}
                    onClick={() => handleTabClick(index)}
                  >
                    {block.settings.title}
                    {activeTab === index && (
                      <span className="absolute left-0 right-0 h-1 bottom-[2px] bg-primary z-[1] transform translate-y-[2px]"></span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className={`grid grid-cols-1 flex-col justify-center w-full lg:flex-row lg:gap-x-10 ${settings.layout !== 'layout3' ? 'lg:pl-[5vw]' : ''} ${settings.layout === 'layout1' ? 'lg:grid-cols-[320px_1fr]' : ''}`}>
            {settings.layout === "layout1" && (
              <div
                className="lg:pt-12 slide-fade-in-element"
                key={animationKey + 1}
              >
                <ExtendedHeadings
                  text={blocks[activeTab].settings.title}
                  type={"h2"}
                  classes={"text-primary h2 text-left mb-4 hidden lg:block"}
                />
                <Paragraph
                  text={blocks[activeTab].settings.description}
                  type={"text-sm font-body mb-4 lg:text-base"}
                />
                <a
                  href={blocks[activeTab].settings.button_link}
                  className={`hidden px-6 py-3 mt-4 text-base font-body uppercase ${blocks[activeTab].settings.button_style} hover:no-underline lg:inline-block lg:w-max lg:min-w-[264px] lg:text-center lg:py-[18px]`}
                >
                  {blocks[activeTab].settings.button_label}
                </a>
              </div>
            )}
            {renderBlock(blocks[activeTab], activeTab)}
          </div>
        </>
      )}
    </div>
  );
};

export default FeaturedCollections;