import React from "react";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import Image from "@arctheme-components/elements/Image/Image";
import ExtendedImage from "../../../elements/ExtendedImage/ExtendedImage";

export const CartItems = ({
  items,
  show_vendor = false,
  show_total_price = true,
}) => {
  const AjaxCartQuantity = "ajax-cart-quantity" as keyof JSX.IntrinsicElements;
  const showItemDivider = false;
  const showOptionNames = true;
  const siteUsesSubscriptions = false;

  // Filter items into one-time purchases and subscriptions
  const oneTimePurchaseItems = items.filter(
    (item) => !item.selling_plan_allocation,
  );
  const subscriptionItems = items.filter(
    (item) => item.selling_plan_allocation,
  );

  const renderLineItem = (line_item, index) => {
    const line_item_price = line_item.price / 100;
    const line_item_quantity = line_item.quantity;
    const indexLineItem = index + 1;
    const size = line_item.options_with_values.find(
      (option) => option.name.toLowerCase() === "size",
    );
    const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

    console.log('Currency', currencySymbol);
    return (
      <React.Fragment key={index}>
        <li
          data-id={indexLineItem}
          id={`${line_item.id}`}
          data-line={indexLineItem}
          key={line_item.id}
          className="item"
        >
          <a href={line_item.url} className="basis-1/3">
            <ExtendedImage
              classes={"item__image"}
              imageUrl={line_item.image}
              alt={line_item.product_title}
              isLazy={true}
              />
          </a>

          <div className="item-details basis-8/12">
            <div className="flex flex-col justify-center gap-1">
              <a href={line_item.url}>
                <h4 className="!text-sm font-body text-primary hover:text-highlight">
                  {line_item.product_title}
                </h4>
              </a>

              <span className="text-sm font-body text-tertiary">
                {
                  line_item.options_with_values.find(
                    (option) => option.name.toLowerCase() === "colour",
                  )?.value
                }
              </span>

              {size && (
                <div className="flex">
                  <span className={`option`} data-option-name={size?.name}>
                    {showOptionNames && (
                      <span className="text-sm font-body text-tertiary">{size?.name}: </span>
                    )}
                    <span className="text-sm font-semibold font-body text-tertiary">{size?.value}</span>
                  </span>
                </div>
              )}

              <div className="flex">
                {!show_total_price ? (
                  <>
                    <span className="text-sm text-tertiary font-body">
                      {currencySymbol}{line_item_price.toFixed(2)}
                    </span>
                    {line_item.compare_at_price && (
                      <span className="text-sm text-tertiary font-body">
                        {currencySymbol}{line_item.compare_at_price / 100}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    <span className="text-sm text-tertiary font-body">
                      {currencySymbol}{(line_item_price * line_item.quantity).toFixed(2)}
                    </span>
                    {line_item.compare_at_price && (
                      <span className="text-sm text-tertiary font-body">
                        {currencySymbol}
                        {(line_item.compare_at_price / 100) *
                          line_item.quantity}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>

            {show_vendor && (
              <small className={`item-vendor`}>{line_item.vendor}</small>
            )}

            <div
              className={`flex items-center justify-between quantity-selector gap-4 mt-3`}
            >
              <AjaxCartQuantity>
                <div className="flex items-center w-full h-8 border max-w-[100px] rounded-xl border-tertiary">
                  <button
                    data-ajax-cart-quantity-minus
                    className="w-auto h-auto px-2 border-none"
                  >
                    <span className="text-xs text-primary">
                      <svg fill="#323D3E" width="12px" height="12px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                        <path d="M216,132H40a4,4,0,0,1,0-8H216a4,4,0,0,1,0,8Z" fill="#323D3E" />
                      </svg>
                    </span>
                  </button>
                  <input
                    data-ajax-cart-quantity-input={`${indexLineItem}`}
                    value={line_item_quantity}
                    type="number"
                    className="!pb-1 quantity-control-input !text-xs text-tertiary !bg-transparent"
                    data-ajax-cart-static-element
                    readOnly={true}
                  />
                  <button
                    data-ajax-cart-quantity-plus
                    className="w-auto h-auto px-2 border-none"
                  >
                    <span className="text-xs icon-plus text-tertiary"></span>
                  </button>
                </div>
              </AjaxCartQuantity>

              <a
                className={`item__remove text-xs underline text-tertiary font-body hover:text-highlight`}
                data-ajax-cart-request-button={`/cart/change?id=${line_item.key}&quantity=0`}
              >
                Remove
              </a>
            </div>

            <div
              className="flex text-xs !bg-transparent errors font-body !text-left !text-[#F8624E]"
              style={{ padding: 0 }}
              data-ajax-cart-errors={`${line_item.key}`}
            ></div>

          </div>
        </li>
        {showItemDivider && <hr className="border-none h-[1px] bg-[#ECEEEF]" />}
      </React.Fragment>
    );
  };

  return (
    <div className={"relative"}>
      {items.length > 0 && (
        <>
          {siteUsesSubscriptions && subscriptionItems.length > 0 && (
            <ul
              id="subscription-items"
              className="flex flex-col flex-grow px-4 items-list"
            >
              <h2 className={"b3__regular"}>Subscription products</h2>
              {subscriptionItems.map((item, index) =>
                renderLineItem(item, index),
              )}
            </ul>
          )}

          {siteUsesSubscriptions && oneTimePurchaseItems.length > 0 && (
            <ul
              id="one-time-purchase-items"
              className="flex flex-col flex-grow px-4 items-list"
            >
              <h2 className={"b3__regular"}>One time purchase</h2>
              {oneTimePurchaseItems.map((item, index) =>
                renderLineItem(item, index),
              )}
            </ul>
          )}

          {!siteUsesSubscriptions && items.length > 0 && (
            <ul
              id="one-time-purchase-items"
              className="flex flex-col flex-grow px-4 items-list lg:px-8"
            >
              {items.map((item, index) => renderLineItem(item, index))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};
