import * as React from "react";
import { debounce } from "lodash";
import { useState, useCallback, useRef } from "react";
import useSearchSpring from "@src/hooks/useSearchSpring";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import ExtendedProductTile from "@stories/components/ProductTile/ProductTile";

export const HeaderSearch = ({ settings }) => {
  const [currentView, setCurrentView] = useState("product");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { loading, autocompleteSearch, searchResults, searchResultsFacets, suggestResults, trendingResults, clearSearchResults, toggleSearchBar } = useSearchSpring(null);
  const { id } = settings.section;

  const debouncedSearch = useRef(
    debounce((value) => {
      autocompleteSearch(value);
      setIsSearchOpen(true);
    }, 300)
  ).current;

  const handleInputSearch = (value) => {
    const isValidSearch = /^[a-zA-Z0-9\s]*$/.test(value);
    if (!isValidSearch) {
      setSearchQuery(value);
      clearSearchResults();
      setIsSearchOpen(true);
      return;
    }

    setSearchQuery(value);
    debouncedSearch(value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setIsSearchOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      window.location.href = `/search?q=${encodeURIComponent(searchQuery)}`;
    }
  };

  const handleCloseSearch = () => {
    const closeSearch = new CustomEvent('close-header-search', {});
    window.dispatchEvent(closeSearch);
    setSearchQuery("");
    clearSearchResults();
  };

  const handleFocus = () => {
    setIsFocused(true);
    toggleSearchBar(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="relative header-search bg-accent">
      <div className="container py-8 mx-auto">
        <div className="relative">
          <input
            type="text"
            name="q"
            value={searchQuery}
            onChange={(e) => handleInputSearch(e.target.value)}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="Search..."
            className="w-full py-2 pl-10 pr-10 border border-gray-300 rounded-md"
            autoComplete="off"
            data-search-input
          />
          <i className="absolute text-xl transform -translate-y-1/2 top-1/2 left-3 icon-search"></i>

          {searchQuery && (
            <button
              onClick={handleClearSearch}
              className="absolute hidden transform -translate-y-1/2 top-1/2 -right-3"
            >
              <i className="text-xs icon-close"></i>
            </button>
          )}

          <button
            onClick={handleCloseSearch}
            className="absolute w-auto transform -translate-y-1/2 top-1/2 -right-3"
          >
            <i className="text-xs icon-close"></i>
          </button>
        </div>

        {trendingResults?.length > 0 && (
          <div className="flex items-center gap-3 py-5 slide-fade-in-element">
            <ExtendedHeadings
              text={'Trending:'}
              type={"h4"}
              classes={`text-tertiary !text-sm`}
            />
            <div className="flex gap-4 overflow-x-auto whitespace-nowrap">
              {trendingResults.map((result, index) => (
                <a
                  href={`/search?q=${encodeURIComponent(result.searchQuery)}`}
                  key={index}
                  className="text-sm font-body text-primary hover:text-highlight"
                >
                  {result.searchQuery}
                </a>
              ))}
            </div>
          </div>
        )}

        {isSearchOpen && (
          <div className="slide-fade-in-element">
            <div className="w-full">
              {loading ? (
                <div className="flex items-center justify-center my-4">
                  <div className="w-6 h-6 border-t-4 border-b-4 rounded-full border-primary animate-spin"></div>
                </div>
              ) : searchResults.length === 0 && searchQuery ? (
                <div className="py-5 text-sm text-left text-primary font-body slide-fade-in-element">
                  No results found for "<strong>{searchQuery}</strong>". Please try another search.
                </div>
              ) : (
                <div className={`flex w-full gap-4 lg:flex-row lg:gap-10 ${Object.keys(suggestResults).length > 0 ? 'py-5' : ''}`}>
                  {suggestResults && (
                    <div className="hidden lg:block lg:w-2/12">
                      <div>
                        {suggestResults.suggested?.text && (
                          <div className="mb-4 lg:mb-6">
                            <ExtendedHeadings
                              text={'Suggestions:'}
                              type={"h4"}
                              classes={`text-tertiary !text-sm mb-2`}
                            />
                            <div>
                              <a
                                href={`/search?q=${encodeURIComponent(suggestResults.suggested.text)}`}
                                className="text-sm/6 font-body text-primary hover:text-highlight"
                              >
                                {suggestResults.suggested.text}
                              </a>
                            </div>
                          </div>
                        )}

                        {suggestResults.alternatives?.length > 0 && (
                          <div className="mb-4 lg:mb-6">
                            <ExtendedHeadings
                              text={'Did you mean:'}
                              type={"h4"}
                              classes={`text-tertiary !text-sm mb-2`}
                            />
                            <div>
                              {suggestResults.alternatives.map((alternative, index) => (
                                <a
                                  href={`/search?q=${encodeURIComponent(alternative.text)}`}
                                  key={index}
                                  className="text-sm/6 font-body text-primary hover:text-highlight"
                                >
                                  {alternative.text}
                                </a>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>

                      {searchResultsFacets && (
                        <div>
                          {searchResultsFacets
                            .filter(facet => facet.field === 'variant_tog' || facet.field === 'product_type' || facet.field === 'collection_name')
                            .map((facet, index) => (
                              <div key={index} className="mb-4 lg:mb-6">
                                <ExtendedHeadings
                                  text={`${facet.label}:`}
                                  type={"h4"}
                                  classes={`text-tertiary !text-sm mb-2`}
                                />
                                <ul>
                                  {facet.values.slice(0, 5).map((value, idx) => (
                                    <li key={idx} className="text-sm/6 text-primary hover:text-highlight">
                                      <a
                                        href={`/search?q=${encodeURIComponent(searchQuery)}&filter.${facet.field}=${encodeURIComponent(value.value)}`}
                                        className="text-sm/6 font-body text-primary hover:text-highlight"
                                      >
                                        {value.label} ({value.count})
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="w-full">
                    <div
                      id="product-grid"
                      className={`grid grid-cols-2 lg:grid-cols-4 gap-x-[20px] lg:gap-x-[24px] gap-y-[24px] lg:gap-y-[24px] auto-cols-max slide-fade-in-element`}
                      data-id={id}
                    >
                      {searchResults?.slice(0, 4).map((result) => (
                        <React.Fragment key={result.id}>
                          <div className="product-grid__inner slide-fade-in-element">
                            <ExtendedProductTile
                              product={result}
                              badges={result.tags}
                              tog={result.productTogs}
                              product_swatches={result.colorPatterns}
                              source={'searchspring'}
                              image_placeholder={settings.globals.product_img_placeholder}
                              view={currentView}
                            />
                          </div>
                        </React.Fragment>
                      ))}
                    </div>

                    {searchResults?.length > 0 && !loading && (
                      <div className="mt-8 text-center">
                        <a
                          href={`/search?q=${encodeURIComponent(searchQuery)}`}
                          className="inline-block px-6 py-3 text-base uppercase font-body btn-primary hover:no-underline hover:!bg-accent lg:text-center"
                        >
                          View All Results
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderSearch;