import React, { useState, useEffect, useRef } from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { BlogArticleTile } from "@project-stories/blog/BlogArticleTile/BlogArticleTile";
import CarouselNavigation from "@project-stories/client/NavigationElements/CarouselNavigation";
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import Flickity from 'flickity';
import "flickity/css/flickity.css";

const CarouselLayout = ({ initialArticles, settings }) => {
  const isMobile = useMobileBreakpoint("1024px");
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(20);
  const [articles, setArticles] = useState(initialArticles || []);
  const [currentArticles, setCurrentArticles] = useState(
    initialArticles?.slice(0, articlesPerPage) || [],
  );

  const carouselRef = useRef(null);

  const { section, blocks } = settings;
  let flexDirection = "flex-row";
  let navArrowsPosition = isMobile
    ? section.nav_arrows_position_mobile
    : section.nav_arrows_position;

  switch (navArrowsPosition) {
    case "bottom":
      flexDirection = "flex-col";
      break;
  }

  const contentBlock = blocks?.find((block) => block.type === "content_block");

  useEffect(() => {
    const handleArticlesComplete = (event) => {
      setCurrentPage(1);
      setArticles(event.detail.articles);
    };

    const handleNextPage = (event) => {
      if (event.detail.type === "pagination") {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("blog-articles-complete", handleArticlesComplete);
    window.addEventListener("nextpage", handleNextPage);

    return () => {
      window.removeEventListener(
        "blog-articles-complete",
        handleArticlesComplete,
      );
      window.removeEventListener("nextpage", handleNextPage);
    };
  }, []);

  useEffect(() => {
    const indexOfLastArticle = currentPage * articlesPerPage;
    const newArticles = articles.slice(0, indexOfLastArticle);
    setCurrentArticles(newArticles);
  }, [currentPage, articles, articlesPerPage]);

  useEffect(() => {
    if (carouselRef.current && currentArticles.length > 0) {
      const flkty = new Flickity(carouselRef.current, {
        wrapAround: true,
        pageDots: false,
        prevNextButtons: true,
        contain: false,
        cellAlign: 'left',
        adaptiveHeight: true,
        freeScroll: true,
      });

      return () => {
        flkty.destroy();
      };
    }
  }, [currentArticles.length]);

  const contentBlockRender = () => (
    <div className="flex flex-col space-y-4">
      <Headings
        text={contentBlock.settings.title}
        type={contentBlock.settings.heading_type}
      />
      <Paragraph
        text={contentBlock.settings.subtext}
        type={contentBlock.settings.subtext_type}
      />
    </div>
  );

  return (
    <>
      <div
        className={`blog-articles carousel-item-container flex ${flexDirection} w-full`}
      >
        {contentBlock && contentBlock.settings.position == "left" && (
          <div className={"section-leftrow__details flex basis-[20%]"}>
            {contentBlockRender()}
          </div>
        )}
        
        {/* {!isMobile && section.nav_arrows_position == "within" && currentArticles.length > 0 && (
          <CarouselNavigation
            shiftPageDots={true}
            carouselRef={carouselRef}
            spread={true}
          />
        )} */}

        {currentArticles.length > 0 && (
          <div
            className={`flex-col w-full max-w-full carousel slide-fade-in-element lg:flex lg:justify-end`}
            ref={carouselRef}
          >
            {currentArticles.map((article, index) => (
              <div
                className="carousel-cell mr-3 !px-0 article w-7/12 md:w-5/12 xl:w-[28%] lg:mr-6"
                key={index}
              >
                <BlogArticleTile
                  article={article}
                  show_excerpt={false}
                  settings={settings}
                />
              </div>
            ))}
          </div>
        )}
        {currentArticles.length <= 0 && (
          <div className={"blog-articles-empty"}>
            <Paragraph text={"There are no articles."} type={"b2"} />
          </div>
        )}

        {isMobile && section.nav_arrows_position_mobile == "bottom" && (
          <div
            className={
              "section-bottomrow flex flex-row justify-between items-center mt-6"
            }
          >
            <CarouselNavigation
              shiftPageDots={true}
              carouselRef={carouselRef}
            />
          </div>
        )}

        {isMobile && section.nav_arrows_position_mobile == "hidden" && (
          <div
            className="hidden"
          >
            <CarouselNavigation
              shiftPageDots={true}
              carouselRef={carouselRef}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CarouselLayout;
