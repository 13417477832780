import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie'

export const hasBodyClass = (className) => {
  return document.body.classList.contains(className)
}

export default {
  /**
   * Emit a custom event
   * @param  {String} type   The event type
   * @param  {Object} detail Any details to pass along with the event
   * @param  {Node}   elem   The element to attach the event to
   */
  emitEvent(type, detail = {}, elem = document) {
    if (!type) return

    let event = new CustomEvent(type, {
      bubbles: true,
      cancelable: true,
      detail: detail,
    })

    return elem.dispatchEvent(event)
  },
    getDomain (use_local = true) {
      if (use_local) {
        if (location.origin.includes('127.0.0.1')) {
          return location.origin;
        }
      }
      return `https://${Shopify.shop}`
    },
  randomNumber(min = 0, max = 1000) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  },
  debounce(fn, wait) {
    let t
    return (...args) => {
      clearTimeout(t)
      t = setTimeout(() => fn.apply(this, args), wait)
    }
  },
  truncateLongTitle(input) {
    return input.length > 5 ? `${input.substring(0, 18)}...` : input
  },
  async fetchHTML(endpoint) {
    return await fetch(endpoint)
      .then((response) => response.text())
      .then((responseText) => {
        return new DOMParser().parseFromString(responseText, 'text/html')
      })
  },

  updateUrlParameters(originalUrl, params) {
    const url = new URL(originalUrl.toString());

    for (const [key, value] of Object.entries(params)) {
      if (!value) {
        url.searchParams.delete(key);
      } else {
        url.searchParams.set(key, value.toString());
      }
    }

    return url.toString();
  },

  getSSCookieId(cookie) {
    let ssId = Cookies.get(cookie);
    if (!ssId) {
      ssId = uuidv4();
      Cookies.set(cookie, ssId);
    }
    return ssId;
  },

  cleanupGlobalId(id) {
    return id?.replace(/^\["|"\]$/g, '')
  },

  formatPrice(priceInCents) {
      const currencyCode = window.Alpine?.store('currency').getCurrencyCode();
      let currencyFormat = 'en-US';

      // Need to manually handle these until we remove formatPrice
      if (currencyCode == 'GBP'){
          currencyFormat = 'en-GB';
      }

      return new Intl.NumberFormat(currencyFormat, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(priceInCents / 100);
  },

  updateUrlAndFilters(filters) {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    let queryStringUrl = ""

    if (searchParams.has('q')) {
      const currentQValue = searchParams.get('q');
      queryStringUrl = `?q=${currentQValue}&${filters}`
    } else {
      queryStringUrl = `?${filters}`
    }

    const newUrl = `${queryStringUrl}`;

    window.history.pushState(null, "", newUrl);

    return newUrl;
  }
}
