import React, { useState } from "react";
import { FAQTabs } from "../FAQTabs/FAQTabs";
import { TextField } from "@arctheme-components/elements/TextField/TextField";
import { Headings } from "@arctheme-components/elements/Headings/Headings";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { Button } from "@arctheme-components/elements/Button/Button";
import { Accordion } from "@project-stories/elements/Accordion/Accordion";
import Image from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";
import { Collapsible } from "@project-stories/elements/Collapsible/Collapsible";
import { Media } from "@project-stories/elements/Media/Media";

const TwoColumnLayout = ({ blocks, settings, isMobile }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("All");
  const [visibleCount, setVisibleCount] = useState(settings.load_more_limit);

  const faqItems = blocks
    .filter((block) => block.type === "column_block")
    .map((faqItem) => ({
      title: faqItem.settings.title,
      content: faqItem.settings.content,
      image: faqItem.settings.image,
      video: faqItem.settings.video,
      category: faqItem.settings.category,
      filter_tag: faqItem.settings.filter_tag,
      column: faqItem.settings.column,
    }));

  const imageBlock = blocks.find((block) => block.type === "column_image");
  const ctaBlock = blocks.find((block) => block.type === "column_CTA");

  const filteredFaqItems = faqItems.filter((item) => {
    const matchesSearch = item.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesCategory =
      activeTab === "All" || item.filter_tag === activeTab;
    return matchesSearch && matchesCategory;
  });

// Split the filteredFaqItems into left and right based on the `column` value
const leftFilteredFaqItems = filteredFaqItems.filter(item => item.column === 1);
const rightFilteredFaqItems = filteredFaqItems.filter(item => item.column === 2);

  const uniqueFilterTags = [
    ...new Set(faqItems.map((item) => item.filter_tag)),
  ];

  const tabs = [
    { label: "All FAQ's", value: "All" },
    ...uniqueFilterTags.map((tag) => ({
      label: tag,
      value: tag,
    })),
  ];

  const renderFAQ = () => (
    <>
      {(settings.show_filter_bar || settings.show_search_bar) && (
        <div className="w-full faq__container-filters">
          {settings.show_filter_bar && (
            <FAQTabs
              tabs={tabs}
              activeTab={activeTab}
              onTabChange={setActiveTab}
              settings={settings}
            />
          )}
          {settings.show_search_bar && (
            <div className="py-[16px]">
              <TextField
                placeholder="Search our FAQ's"
                icon="search"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </div>
          )}
        </div>
      )}
      <div className="flex w-full faq__container-accordion">
        <div className="pr-4 faq__container-accordion-left">
          {rightFilteredFaqItems.map((item, index) => (
            <Collapsible
              key={index}
              title={item.title}
              titleClass={`font-body font-semibold text-base/7 lg:text-xl`}
              contentClass="b2 border-b"
              openIcon="icon-chevron-down-white text-[10px]"
              closeIcon="icon-chevron-down-white rotate-180 text-[10px]"
              iconColor={settings.icon_and_border_color}
              borderColor={settings.icon_and_border_color || '#FDFCFC'}
            >
              {(item.video || item.image) && (
                <div className={"accordion__content-column"}>
                  <Media
                    videoItem={item.video}
                    imageItem={item.image}
                    index={index}
                  />
                </div>
              )}
              {item.content && (
                <div className={"accordion__content-column"}>
                  <Paragraph
                    text={item.content}
                    type={`${isMobile ? "text-sm font-body pb-4" : "text-base font-body pb-4"}`}
                  />
                </div>
              )}
            </Collapsible>
          ))}
        </div>
      </div>
      {settings.use_load_more && visibleCount < filteredFaqItems.length && (
        <div className="faq__container-button-container">
          <div className="faq__container-button">
            <Button
              label="Load More"
              style="secondary"
              onClick={() => setVisibleCount(visibleCount + 10)}
            />
          </div>
        </div>
      )}
    </>
  );

  const renderHeaderContent = () => (
    <>
      {(isMobile ? settings.title_mobile : settings.title) && (
        <Paragraph
          text={isMobile ? settings.title_mobile : settings.title}
          type="font-display font-bold text-2xl/10 lg:text-4xl"
        />
      )}
      {settings.subtext && (
        <div className="text-left faq__container-subcopy">
          <Paragraph text={settings.subtext} type="font-body font-normal text-sm lg:text-base" />
        </div>
      )}
      {settings.button_label && settings.button_url && (
        <a href={settings.button_url} className="text-base underline font-body">
          {settings.button_label}
        </a>
      )}
      {ctaBlock && ctaBlock.settings.column === 1 && (
        <Button
          label={ctaBlock.settings.cta_text}
          style={ctaBlock.settings.cta_style}
          link={ctaBlock.settings.cta_url}
        />
      )}
    </>
  );
  
  const buttonStyle = "text";
  return (
    <div className="faq">
      {settings.full_width_title && (
        <div className="w-full pb-6 text-center lg:pb-8">
          {renderHeaderContent()}
        </div>
      )}
      <div
        className={`flex flex-row space-x-6 lg:space-x-10 items-${settings.content_alignment_mobile} lg:items-${settings.content_alignment_desktop}
          justify-${settings.content_position_desktop} mx-auto py-8 px-5 lg:py-16 lg:px-0 lg:grid ${settings.full_width_title ? 'lg:grid-cols-2' : 'lg:grid-cols-[400px,1fr]'}`}
      >
        <div className={`faq-details space-y-[48px]`}>
          <div className={`faq-top-section flex flex-col ${settings.full_width_title ? '' : 'space-y-3 lg:space-y-4' }`}>
            {!settings.full_width_title && (
              renderHeaderContent()
            )}

              {leftFilteredFaqItems.map((item, index) => (
                  <Collapsible
                      key={index}
                      title={item.title}
                      titleClass={`font-body font-semibold text-base/7 lg:text-xl`}
                      contentClass={`b2 border-b border-[#FDFCFC]`}
                      openIcon="icon-chevron-down-white text-[10px]"
                      closeIcon="icon-chevron-down-white rotate-180 text-[10px]"
                  >
                      {(item.video || item.image) && (
                          <div className={"accordion__content-column"}>
                              <Media
                                  videoItem={item.video}
                                  imageItem={item.image}
                                  index={index}
                              />
                          </div>
                      )}
                      {item.content && (
                          <div className={"accordion__content-column"}>
                              <Paragraph
                                  text={item.content}
                                  type={`${isMobile ? "text-sm font-body pb-4" : "text-base font-body pb-4"}`}
                              />
                          </div>
                      )}
                  </Collapsible>
              ))}
          </div>
          {imageBlock && imageBlock.settings.column == 1 && (
            <Image imageUrl={imageBlock.image_url} alt={"Image"} />
          )}
          {settings.faq_to_left && renderFAQ()}
        </div>
        <div className={`faq-data basis-1/2`}>
          {imageBlock && imageBlock.settings.column == 2 && (
            <Image imageUrl={imageBlock.image_url} alt={"Image"} />
          )}

          {ctaBlock && ctaBlock.settings.column == 2 && (
            <Button
              label={ctaBlock.settings.cta_text}
              style={`${ctaBlock.settings.cta_style}`}
              link={`${ctaBlock.settings.cta_url}`}
            />
          )}

          {!settings.faq_to_left && renderFAQ()}
        </div>
      </div>
    </div>
  );
};

export default TwoColumnLayout;