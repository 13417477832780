import React, { useState, useEffect } from "react";

declare const window: Window &
  typeof globalThis & {
    theme: {
      filesURL: any;
    };
  };

export const CollectionFilters = ({ changeFilter, filters }) => {
  const [selectedFilters, setSelectedFilters] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const initialFilters = Array.from(params.entries())
      .filter(([key]) => key.startsWith("filter."))
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    setSelectedFilters(initialFilters);
  }, []);

  const [openDropdown, setOpenDropdown] = useState(null);

  const handleSelectFilter = (filterField: string, value: any) => {
    setSelectedFilters((prevFilters) => {
      let updatedFilters = prevFilters || "";
      let filtersArray = updatedFilters.split("&").filter(Boolean);
  
      if (filterField === "ss_price") {
        const lowFilter = `filter.${filterField}.low=${value.low}`;
        const highFilter = `filter.${filterField}.high=${value.high}`;
  
        const lowExists = filtersArray.includes(lowFilter);
        const highExists = filtersArray.includes(highFilter);
  
        if (lowExists && highExists) {
          filtersArray = filtersArray.filter(
            (filter) => filter !== lowFilter && filter !== highFilter
          );
        } else {
          filtersArray = filtersArray.filter(
            (filter) =>
              !filter.includes(`filter.${filterField}.low=`) &&
              !filter.includes(`filter.${filterField}.high=`)
          );

          if (value.low !== undefined && value.high !== undefined) {
            filtersArray.push(lowFilter);
            filtersArray.push(highFilter);
          }
        }
      } else {
        const newFilter = `filter.${filterField}=${value.value}`;
        const filterExists = filtersArray.includes(newFilter);
  
        if (filterExists) {
          filtersArray = filtersArray.filter((filter) => filter !== newFilter);
        } else {
          filtersArray.push(newFilter);
        }
      }

      updatedFilters = filtersArray.join("&");
      // console.log('updatedFilters', updatedFilters)
      // updateUrlWithFilters(updatedFilters);
      changeFilter(updatedFilters);
      return updatedFilters;
    });
    setOpenDropdown(null);
  };

  const getColourImageUrl = (colour) => {
    const formattedColour = colour.toLowerCase().replace(/\s+/g, "-");
    const fileURL = `${window.theme.filesURL}${formattedColour}.png`;
    return fileURL;
  };

  useEffect(() => {
    if (selectedFilters) {
      changeFilter(selectedFilters);
    }
  }, [selectedFilters]);

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const removeFilter = (filterField: string, value: any) => {
    handleSelectFilter(filterField, value);
  };

  const clearAllFilters = () => {
    setSelectedFilters("");
    // updateUrlWithFilters("");
    changeFilter("");
  };
  
  type PriceFilterValue = {
    low: string | null;
    high: string | null;
  };
  
  type Filter = {
    filterField: string;
    value: string | PriceFilterValue;
  };

  const parseSelectedFilters = (): Filter[] => {
    const filtersArray = selectedFilters.split("&").filter(Boolean);
    const parsedFilters: Filter[] = [];
  
    filtersArray.forEach((filter) => {
      const [key, value] = filter.split("=");
      const [, filterField, rangeType] = key.split(".");
  
      if (filterField === "ss_price") {
        let priceFilter = parsedFilters.find(
          (f) => f.filterField === "ss_price"
        ) as Filter | undefined;
  
        if (!priceFilter) {
          priceFilter = {
            filterField: "ss_price",
            value: { low: null, high: null },
          };
          parsedFilters.push(priceFilter);
        }
  
        const priceValue = priceFilter.value as PriceFilterValue;
  
        if (rangeType === "low") {
          priceValue.low = value;
        } else if (rangeType === "high") {
          priceValue.high = value;
        }
      } else {
        parsedFilters.push({
          filterField,
          value: value,
        });
      }
    });
  
    return parsedFilters.map((filter) => {
      if (filter.filterField === "ss_price") {
        const { low, high } = filter.value as PriceFilterValue;
        return {
          filterField: "ss_price",
          value: `$${low} to $${high}`,
        };
      }
      return filter;
    });
  };

  const selectedFiltersArray = parseSelectedFilters();

  return (
    <div>
      <div className="grid grid-cols-2 gap-2.5 lg:flex lg:items-center lg:gap-y-4">
        <span className="hidden text-xl font-semibold text-primary font-body lg:block">
          Filters :
        </span>

        {filters.map((filter, index) => (
          <div key={index} className="relative">
            <button
              onClick={() => toggleDropdown(index)}
              className="px-4 py-2 bg-[#EBF3F1] text-primary font-semibold flex items-center justify-between min-w-[100px] h-8 min-h-8"
            >
              <span className="font-semibold capitalize text-primary font-body text-base/[22px]">
                {filter.label}
              </span>
              <i className="text-[8px] icon icon-Down"></i>
            </button>

            {openDropdown === index && (
              <div className="absolute bg-secondary mt-2 z-10 left-0 origin-top-right rounded-md shadow-lg w-full ring-1 ring-[#323D3E33] focus:outline-none p-4 lg:min-w-max">
                {filter.values.map((option: any, idx: number) => (
                  <div key={idx} className="flex items-center p-1.5 space-x-2">
                    {filter.field !== "variant_colour" ? (
                      <label
                        htmlFor={`${idx}-${option.label.toLowerCase()}`}
                        className="flex items-center text-sm font-semibold cursor-pointer text-primary font-body lg:text-base hover:text-highlight"
                      >
                        <input
                          id={`${idx}-${option.label.toLowerCase()}`}
                          type="checkbox"
                          className="mr-2 hover:!border-black"
                          checked={option.active}
                          onChange={() =>
                            handleSelectFilter(filter.field, option)
                          }
                        />
                        {option.label}
                      </label>
                    ) : (
                      <label
                        htmlFor={`${idx}-${option.label.toLowerCase()}`}
                        className="flex items-center space-x-1"
                      >
                        <input
                          id={`${idx}-${option.label.toLowerCase()}`}
                          type="checkbox"
                          className="mr-1"
                          checked={option.active}
                          onChange={() =>
                            handleSelectFilter(filter.field, option)
                          }
                        />
                        <img
                          src={getColourImageUrl(option.value)}
                          alt={option.label}
                          className="w-4 h-4 rounded-full"
                        />
                        <span className="text-sm font-semibold cursor-pointer text-primary font-body lg:text-base hover:text-highlight">
                          {option.label}
                        </span>
                      </label>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex items-center mt-2.5 mb-6 gap-[14px] flex-wrap lg:flex-nowrap lg:mt-5 lg:mb-0">
        {selectedFiltersArray.map((filter, index) => (
          <div
            key={index}
            className="flex items-center bg-[#F4F4F4] px-[15px] py-[5px] gap-[14px]"
          >
            {filter.filterField === "ss_price" ? (
              <span className="text-base/[22px] font-semibold font-body text-primary">
                {filter.value as string}
              </span>
            ) : filter.filterField !== "variant_colour" ? (
              <span className="text-base/[22px] font-semibold font-body text-primary">
                {typeof filter.value === 'string' ? filter.value.replace(/_/g, " ") : ''}
              </span>
            ) : (
              <div className="flex justify-between gap-x-2.5 items-center">
                <img
                  src={getColourImageUrl(filter.value as string)}
                  alt={filter.value as string}
                  className="w-4 h-4 rounded-full"
                />
                <span className="text-base/[22px] font-semibold font-body text-primary">
                  {typeof filter.value === 'string' ? filter.value.replace(/_/g, " ") : ''}
                </span>
              </div>
            )}
            <button
              onClick={() =>
                removeFilter(filter.filterField, { value: filter.value })
              }
              className="!h-auto min-h-[auto] w-auto text-base font-semibold text-primary p-0"
            >
              <i className="icon-close text-[10px]"></i>
            </button>
          </div>
        ))}

        {selectedFiltersArray.length > 0 && (
          <button
            onClick={clearAllFilters}
            className="text-sm font-normal underline normal-case text-primary !h-auto min-h-[auto] w-auto p-0"
          >
            Clear all filters
          </button>
        )}
      </div>
    </div>
  );
};

export default CollectionFilters;