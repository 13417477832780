import React, { useState, useEffect, useRef } from "react";
import ExtendedImageComponent from "@stories/elements/ExtendedImage/ExtendedImage";
import Flickity from 'flickity';
import "flickity/css/flickity.css";
import './ExtendedProductGallery.scss';

export const ExtendedProductGallery = ({
  images,
  onImageClick,
  galleryType,
  videoUrl,
  product,
  badges
}) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const imageContainerRef = useRef(null);
  const imageRefs = useRef(new Array(images.length).fill(null).map(() => React.createRef()));

  const hasBabyBountyAward = product.tags?.includes('2023 bounty baby awards') || false;

  const carouselRef = useRef(null);
  const flktyRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      flktyRef.current = new Flickity(carouselRef.current, {
        wrapAround: true,
        pageDots: false,
        prevNextButtons: false,
        contain: true,
        cellAlign: 'left',
        adaptiveHeight: true,
        freeScroll: false,
      });

      flktyRef.current.on('change', (index) => {
        setCurrentIndex(index);
        setSelectedImage(images[index]);
      });

      return () => {
        if (flktyRef.current) {
          flktyRef.current.destroy();
        }
      };
    }
  }, []);

  const handleZoomClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
    setSelectedImage(images[index]);
    const targetImage = imageRefs.current[index].current;

    if (targetImage && imageContainerRef.current) {
      const container = imageContainerRef.current;
      container.scrollTo({
        top: targetImage.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleThumbnailCarouselClick = (index) => {
    setCurrentIndex(index);
    setSelectedImage(images[index]);

    if (flktyRef.current) {
      flktyRef.current.select(index);
    }
  };

  const handleVideoClick = () => {
    const videoElement = document.getElementById("product-video");
    if (videoElement) {
      const offset = -500; // Adjust this value as needed
      const elementPosition =
        videoElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      // @ts-ignore
      videoElement.querySelector("iframe").src += "&autoplay=1";
    }
  };

  return (
    <div>
      {galleryType === "tiled" && (
        <>
          <div className="relative image-gallery">
            {hasBabyBountyAward && badges.bounty_baby_awards && (
              <ExtendedImageComponent
                imageUrl={badges.bounty_baby_awards}
                alt="2023 Baby Bounty Awards"
                isLazy={true}
                classes="absolute top-4 left-4 w-[67px] lg:w-[103px]"
              />
            )}

            {videoUrl && (
              <div
                className="absolute m-[23px] cursor-pointer top-0 left-0 w-[104px] opacity-[0.6] shadow border border-white"
                onClick={handleVideoClick}
              >
                <ExtendedImageComponent
                  imageUrl={images[1].url}
                  alt={images[1].alt}
                  isLazy={true}
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg
                    className="w-12 h-12 text-white"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </div>
              </div>
            )}
            {images.map((image, index) => (
              <div
                key={index}
                className="image-item"
                onClick={() => onImageClick(image)}
              >
                <img
                  src={image.url} 
                  alt={product.title}
                />
              </div>
            ))}
            {videoUrl && (
              <div id="product-video" key={"video"} className="video-item">
                <div
                  className="video-iframe relative h-[766px] w-full"
                  data-v-2da62827=""
                >
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src={videoUrl}
                    width="100%"
                    height="100%"
                    data-v-2da62827=""
                  ></iframe>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {galleryType === "stacked" && (
        <div className="flex">
          <div className="relative flex-grow lg:absolute lg:h-full lg:w-full">
            {hasBabyBountyAward && badges.bounty_baby_awards && (
              <ExtendedImageComponent
                imageUrl={badges.bounty_baby_awards}
                alt="2023 Baby Bounty Awards"
                isLazy={true}
                classes="absolute top-4 left-4 w-[67px] lg:w-[103px]"
              />
            )}

            <div
              ref={imageContainerRef}
              className="flex-grow h-full lg:overflow-y-auto hide-scrollbar">
              {images.map((image, index) => (
                <div
                  key={index}
                  ref={imageRefs.current[index]}
                  className="cursor-pointer main-image-item"
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img
                    src={image.url}
                    alt={product.title}
                    className="w-full h-auto"
                  />
                </div>
              ))}
            </div>

            <div className="absolute top-2 right-6">
              <button
                onClick={handleZoomClick}
                className="p-2 text-gray-500 hover:text-gray-700"
              >
                <i className="icon-zoom"></i>
              </button>
            </div>
          </div>

          <div className="absolute flex flex-col py-6 space-y-3 top-10 hide-scrollbar max-h-custom snap-mandatory snap-y lg:overflow-y-auto lg:h-full lg:top-20 lg:right-6 lg:-ml-20">
            {images.map((image, index) => (
              <div
                key={index}
                className={`cursor-pointer thumbnail-item ${index === currentIndex ? 'border border-black' : 'border border-transparent'}`}
                onClick={() => handleThumbnailClick(index)}
              >
                <img
                  src={image.url}
                  alt={product.title}
                  className="object-cover w-28 h-28 max-w-max"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {galleryType === "carousel" && (
        <div className="product-gallery__carousel-wrapper">
          <div className="relative image-carousel">
            {hasBabyBountyAward && badges.bounty_baby_awards && (
              <ExtendedImageComponent
                imageUrl={badges.bounty_baby_awards}
                alt="2023 Baby Bounty Awards"
                isLazy={true}
                classes="absolute top-4 left-4 w-[67px] lg:w-[103px]"
              />
            )}

            <div
              className="w-full h-auto overflow-hidden carousel"
              ref={carouselRef}
            >
              {images.map((image, index) => (
                <div key={index} className={`carousel-cell w-full`}>
                  <ExtendedImageComponent
                    imageUrl={image.url}
                    alt={product.title}
                    isLazy={true}
                  />
                </div>
              ))}

              {videoUrl && (
                <div
                  key={"video"}
                  className={`carousel-cell video-item w-full`}
                  style={{ height: "100%", width: "100%", pointerEvents: "none" }}
                >
                  <div className="h-full video-iframe">
                    <iframe
                      src={videoUrl}
                      width="100%"
                      height="100%"
                    ></iframe>
                  </div>
                </div>
              )}
            </div>

            <div className="flex justify-center my-3.5">
              <div className="flex px-3 space-x-2 overflow-x-auto hide-scrollbar">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`flex-shrink-0 w-16 thumbnail-item transition-all duration-300 ease-in-out transform border ${index === currentIndex ? 'border-primary' : 'border-transparent'}`}
                    onClick={() => handleThumbnailCarouselClick(index)}
                  >
                    <ExtendedImageComponent
                      imageUrl={image.url}
                      alt={product.title}
                      classes="!w-16 !h-16 aspect-square object-cover"
                      isLazy={true}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="absolute top-2 right-2">
              <button
                onClick={handleZoomClick}
                className="p-2 text-gray-500 hover:text-gray-700"
              >
                <i className="icon-zoom"></i>
              </button>
            </div>
          </div>
        </div>
      )}

      {isFullScreen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-primary/70">
          <div className="relative w-full max-w-2xl p-4">
            <img
              src={images[currentIndex].url}
              alt={images[currentIndex].alt}
              className="w-full h-auto"
            />
            <button
              onClick={handleCloseFullScreen}
              className="absolute z-10 transform translate-x-1/2 -translate-y-1/2 right-6 top-4"
            >
              <i className="icon-close text-accent bg-tertiary p-2 text-[10px]"></i>
            </button>
          </div>
        </div>
      )}

      {galleryType === "thumbnail" && (
        <div className="gap-5 product-image-gallery lg:max-h-[500px]">
          <div className="thumbnail-list overflow-y-auto hide-scrollbar gap-2.5">
            {images.map((image, index) => (
              <div
                key={index}
                className={`thumbnail-item ${selectedImage === image ? "selected" : ""}`}
                onClick={() => setSelectedImage(image)}
              >
                <img
                  src={image.url}
                  alt={product.title}
                />
              </div>
            ))}
          </div>
          <div className="!p-0 main-image">
            <img src={selectedImage.url} alt={selectedImage.alt}  width="500px" height="500px" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtendedProductGallery;
