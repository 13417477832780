import React, { useState } from "react";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import QuickViewModal from "./QuickViewModal";
import './ProductQuickAdd.scss';

export const QuickAdd = ({ settings }) => {
  const { block, products } = settings;
  const [selectedProduct, setSelectedProduct] = useState(null);

  if (!products || products.length === 0) {
    return null;
  }
  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

  const formatPrice = (price) => (price / 100).toFixed(2);

  // Need to update this in the future and move it to a utility
  const createKeyValuePairs = (tags) => {
    const tagsArray = Array.isArray(tags) ? tags : tags ? [tags] : [];
    return tagsArray.reduce((acc, tag) => {
      if (typeof tag === 'string' && tag.includes('|')) {
        const [key, value] = tag.split("|");
        if (value) {
          acc[key] = acc[key] ? [...(Array.isArray(acc[key]) ? acc[key] : [acc[key]]), value] : value;
        }
      }
      return acc;
    }, {});
  };

  const getBadgeColor = (badgeText) => {
    if (typeof badgeText === 'string') {
      let normalizedBadgeText = badgeText.toLowerCase().replace(/[^a-z0-9]/g, '_');

      if (normalizedBadgeText.includes('save')) {
        normalizedBadgeText = 'save';
      }

      const colorKey = `${normalizedBadgeText}_badge_color`;
      const color = settings.promotional_badges ? settings.promotional_badges[colorKey] : '#323D3E';
      return color || '#323D3E';
    }
    return '#323D3E';
  };

  const handleAddToBagClick = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  return (
    <div className="flex flex-col border border-primary/10 rounded-3xl bg-[#FDFCFC] p-6 lg:mt-2">
      <ExtendedHeadings
        text={block.title}
        type={"h3"}
        classes={"!text-xl mb-[2px]"}
      />
      <Paragraph
        type={"text-sm text-primary font-body font-normal mb-2.5 lg:mb-4"}
        text={block.subtext}
      />

      <div>
        {products.map((product, productIndex) => {
          const price = product.price;
          const compareAtPrice = product.compare_at_price;
          const displayStrikethrough = compareAtPrice > price;

          const productTags = createKeyValuePairs(product.tags);
          const promotionalBadges = productTags.promotional_badge ? [].concat(productTags.promotional_badge) : [];

          return (
            <div key={`product-${product.id}-${productIndex}`} className="mb-2">
              <div
                className="grid grid-cols-[114px_1fr] bg-[#fcf9f3] rounded-xl relative gap-x-5"
              >
                <div className="px-0 py-3 lg:p-3">
                  <div className="pointer-events-none z-[5] grid absolute top-3 left-3 text-white gap-y-1 lg:top-4 lg:left-6">
                    {promotionalBadges.map((badge, badgeIndex) => (
                      <span
                        key={`badge-${product.id}-${badgeIndex}`}
                        className="items-center gap-[6px] py-[2.5px] px-2 w-max text-[10px]/[15px] tracking-wider font-semibold font-body flex uppercase"
                        style={{ backgroundColor: getBadgeColor(badge) }}
                      >
                        {badge}
                      </span>
                    ))}
                  </div>
                  <img
                    src={product.images[0].url}
                    alt={product.title}
                    className="object-cover"
                  />
                </div>
                <div className="flex flex-col justify-center pr-3 gap-y-2">
                  <span
                    className="text-white rounded-[20px] items-center gap-[6px] py-[2.5px] px-2 w-max text-[10px]/[15px] tracking-wider font-semibold font-body flex uppercase"
                    style={{ backgroundColor: product.tog_colour }}
                  >
                    {product.tog_title}
                  </span>

                  <ExtendedHeadings
                    text={product.title}
                    type={"h4"}
                    classes={"!text-sm/[18px]"}
                  />
                  <div className="text-sm/[25px] font-body flex gap-x-[10px]">
                    <span className="text-tertiary">{currencySymbol}{formatPrice(price)}</span>
                    {displayStrikethrough && (
                      <span className="line-through text-[#752724] font-semibold">
                        {currencySymbol}{formatPrice(compareAtPrice)}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-span-2">
                <button
                  onClick={() => handleAddToBagClick(product)}
                  className="w-full text-primary transition rounded-[1px] text-xs/5 bg-darker"
                >
                  <i className="icon icon-plus"></i>
                  ADD TO BAG
                </button>
              </div>
            </div>
          );
        })}
      </div>

      {selectedProduct && (
        <QuickViewModal
          settings={settings}
          product={selectedProduct}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default QuickAdd;
