import React from "react";

export const CartSummary = ({ settings }) => {
  const { cart, section, routes } = settings;

  const cart_subtotal = cart.original_total_price;

  // Calculate total savings for items with a selling plan
  const totalSavings = cart.items.reduce((acc, item) => {
    if (item.selling_plan_allocation) {
      const originalPrice = item.selling_plan_allocation.compare_at_price; // Original price before discount
      const discountedPrice = item.selling_plan_allocation.price; // Discounted price (current price)
      const itemSavings =
        ((originalPrice - discountedPrice) / 100) * item.quantity;
      return acc + itemSavings;
    }
    return acc;
  }, 0);
  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

  const totalBeforeSavings = totalSavings + cart_subtotal;

  return (
    <div className="relative right-0 text-right cart__summary">
      <form
        id="cart"
        action={routes.cart_url}
        method="post"
        className="py-5 rounded cart__summary"
      >
        <div className="mb-4 space-y-6 text-left">
          <h3 className="!text-xl font-body pb-3 text-primary border-b border-tertiary/30 w-full">
            Order summary
          </h3>
          <div className="flex flex-col space-y-4 cart__summary-items">
            <div className="flex justify-between cart__summary-items-item">
              <span className="text-sm text-primary font-body">
                Subtotal
              </span>
              <span className="text-sm text-primary font-body">
                {currencySymbol}{(cart.original_total_price / 100).toFixed(2)}
              </span>
            </div>
            {totalSavings > 0 && (
              <div className="flex justify-between cart__summary-items-item">
                <span className="text-sm text-primary font-body">
                  Total Savings
                </span>
                <div className="flex px-2 py-1 border border-solid border-primary/50">
                  -{currencySymbol}{totalSavings.toFixed(2)}
                </div>
              </div>
            )}
            <div className="flex justify-between cart__summary-items-item">
              <span className="text-sm text-primary font-body">
                Shipping
              </span>
              <span className="text-sm text-primary font-body">
                Calculated at checkout
              </span>
            </div>
            <div className="flex justify-between cart__summary-items-item">
              <span className="text-xl font-semibold text-primary font-body">
                Total
              </span>
              <span className="text-xl font-semibold text-primary font-body">{currencySymbol}{(cart.original_total_price / 100).toFixed(2)}</span>
            </div>
          </div>

          <div className="cart__summary-items flex flex-col space-y-[24px] hidden">
            <div className="flex justify-between cart__summary-items-item">
              <span>Number of items</span>
              <span>{cart.item_count}</span>
            </div>
          </div>

          {section.summary_message && (
            <div className="text-sm">{section.summary_message}</div>
          )}
        </div>

        {section.show_cart_note && (
          <>
            <hr />
            <div className="my-2 cart_notes">
              <span className="mt-2">Cart Notes</span>
              <input
                className="w-full py-2 bg-white border text-primary border-tertiary/30 indent-2"
                type="text"
                name="cart_note"
                placeholder="Enter notes for cart"
              />
            </div>
          </>
        )}

        <button
          className="w-full btn-primary"
          type="submit"
          name="checkout"
          form="checkout"
        >
          Checkout
        </button>
      </form>
    </div>
  );
};
